import TranslationFactory from '@/factories/TranslationFactory';
import LabelRepository from '@/repositories/LabelRepository';
import LanguageRepository from '@/repositories/LanguageRepository';
import TranslationRepository from '@/repositories/TranslationRepository';
import store from '@/store';
import {ILanguage, ITranslation} from '@/types/TranslationTypes';

export default class TranslationService {

  static setCurrentLanguage(shortcut: string) {
    store.commit('translations/setCurrentLanguage', shortcut);
    localStorage.setItem('lang', shortcut);
  }

  static async getCurrentLanguage(): Promise<ILanguage | undefined> {
    const language: ILanguage | null = store.getters['translations/language'];

    if (language) {
      return language;
    }

    const languages: ILanguage[] = await this.getLanguagesList();
    if (!languages.length) {
      return undefined;
    }

    const langFromLocalStorage = localStorage.getItem('lang');
    if (!langFromLocalStorage) { // search in LocalStorage
      this.setCurrentLanguage(languages[0].shortcut);
      return languages[0];
    }

    const candidate = languages.find((item) => item.shortcut === langFromLocalStorage);

    if (candidate) {
      this.setCurrentLanguage(candidate.shortcut);
    }

    return candidate;
  }

  static async getLanguagesList(): Promise<ILanguage[]> {
    let languages = store.getters['translations/languagesList'];

    if (!languages.length) {
      languages = await this.getLangs(true);
      store.commit('translations/setLanguages', languages);
    }

    return languages;
  }

  static async getLangs(isPublic: boolean = false) {
    const {data} = isPublic
                   ? await LanguageRepository.getAllPublic()
                   : await LanguageRepository.getAll();

    return data?.map((item: any) => TranslationFactory.toLang(item));
  }

  static storeLang(data: ILanguage) {
    return LanguageRepository.store({
      ...data,
      name: data.name.trim(),
      shortcut: data.shortcut.trim(),
    });
  }

  static updateLang(data: ILanguage) {
    if (data.id) {
      return LanguageRepository.update(data.id, {
        ...data,
        name: data.name.trim(),
        shortcut: data.shortcut.trim(),
      });
    }
    throw new Error('Brak ID');
  }

  static async deleteLang(id: number | string) {
    return LanguageRepository.delete(id);
  }

  // Translations

  static async getTranslations(languageId: number, isPublic: boolean = false) {
    const {data} = isPublic
                   ? await TranslationRepository.getAllPublic(languageId)
                   : await TranslationRepository.getAll(languageId);

    return isPublic ? data.translations : data.map((item: any) => TranslationFactory.toTranslation(item));
  }

  static async updateTranslation(langId: number, translation: ITranslation) {
    return TranslationRepository.storeOrUpdate(langId, translation.labelId, {
      ...translation,
      translation: translation.translation.trim(),
    });
  }

  static async removeTranslation(translation: ITranslation) {
    if (translation.translationId) {
      return TranslationRepository.delete(translation.translationId);
    }
    throw new Error('Translation has not id property');
  }

  // Labels

  static async createLabel(label: string) {
    return LabelRepository.store(label);
  }

  static async updateLabel(translation: ITranslation) {
    return LabelRepository.update(translation.labelId!, {label: translation.label.trim()});
  }

  static async removeLabel(id: number) {
    return LabelRepository.delete(id);
  }
}
