export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
  try {
    const token = localStorage.getItem(key);

    if (token) {
      return JSON.parse(token as string);
    }
    return '';
  } catch (err) {
    return '';
  }
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
