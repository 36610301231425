
import {Component, Prop, Vue} from 'vue-property-decorator';
import {required} from '@/utils/validationRules';
import {resolveError} from '@/utils/notifications';
import ITerm from '@/types/ITerms';
import UserService from '@/services/UserService';
import AuthService from '@/services/AuthService';

@Component({name: 'AcceptTerms'})
export default class AcceptTerms extends Vue {
  loading: boolean = false;
  rules = {
    required: [required],
  };

  @Prop()
  terms!: ITerm[];

  @Prop()
  dialog!: boolean;

  async save() {
    // @ts-ignore
    if (this.$refs.userForm.validate()) {
      try {
        await UserService.acceptTerms(this.terms
            .filter((element) => element.accepted)
            .map((element) => element.termId),
        );
        this.closeDialog();
      } catch (e) {
        resolveError(e, 'accept_terms');
      } finally {
        this.loading = false;
      }
    }
  }

  closeDialog() {
    this.$emit('accepted');
  }

  logout() {
    AuthService.logout();
    this.$emit('logout');
  }
};
