import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {authRoutes} from '@/router/authRoutes';
import {getLocalStorage} from '@/utils/localStorage';
import {isPermission} from '@/utils/perrmissions';
import store from '@/store/index';
import UserService from '@/services/UserService';
import AuthService from '@/services/AuthService';
import {translateApp} from '@/plugins/i18n';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: {name: 'Home'},
    name: 'MainLayout',
    beforeEnter: async (to, from, next) => {
      await translateApp();
      next();
    },
    component: () => import(/* webpackChunkName: "main" */ '../layouts/MainLayout.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Home',
        component: () => import(/* webpackChunkName: "main" */ '../views/Dashboard.vue'),
      },
      {
        path: 'configuration',
        name: 'Configuration',
        component: () => import(/* webpackChunkName: "main" */ '../views/Configuration.vue'),
      },
      {
        path: 'configuration/oauth/:apiType',
        name: 'ConfigurationOAuth',
        component: () => import('@/views/ConfigurationOAuth.vue'),
      },

      {
        path: 'authorize',
        name: 'Authorize',
        component: () => import(/* webpackChunkName: "main" */ '../views/Authorize.vue'),
      },
      {
        path: 'company',
        name: 'Company',
        meta: {
          permissions: ['company.view'],
        },
        component: () => import('@/views/Company.vue'),
      },
      {
        path: 'tax-declarations',
        name: 'TaxDeclarations',
        meta: {
          permissions: ['tax_declaration.view'],
        },
        component: () => import('@/views/TaxDeclarations.vue'),
      },
      {
        path: 'external-incomes',
        name: 'ExternalIncomes',
        meta: {
          permissions: ['external_income.view'],
        },
        component: () => import('@/views/ExternalIncomes.vue'),
      },
      {
        path: 'analyses',
        name: 'Analyses',
        meta: {
          permissions: ['analysis.view'],
        },
        component: () => import('@/views/Analyses.vue'),
      },
      {
        path: 'reports',
        name: 'Reports',
        meta: {
          permissions: ['report.view'],
        },
        component: () => import('@/views/Reports.vue'),
      },
      {
        path: 'reports/:id',
        name: 'ReportShow',
        props: true,
        meta: {
          permissions: ['report.view'],
        },
        component: () => import('@/views/ReportShow.vue'),
      },
      {
        path: 'companies',
        name: 'Companies',
        meta: {
          permissions: ['admin.company_view'],
        },
        component: () => import('@/views/Companies.vue'),
      },
      {
        path: 'users',
        name: 'Users',
        meta: {
          permissions: ['admin.user_view'],
        },
        component: () => import('@/views/Users.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: 'translations',
        name: 'Translations',
        meta: {
          permissions: ['admin.translation'],
        },
        component: () => import('@/views/Translations.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: {name: 'Home'},
  },
  {
    path: '/auth',
    redirect: {name: 'Login'},
    name: 'AuthLayout',
    beforeEnter: async (to, from, next) => {
      await translateApp();
      next();
    },
    component: () => import(/* webpackChunkName: "auth" */ '../layouts/AuthLayout.vue'),
    children: authRoutes,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

// Check user authorization
router.beforeEach(async (to, from, next) => {
  const isAuthorized = !!getLocalStorage('token');
  if (isAuthorized && !store.state.user.id) {
    try {
      await UserService.getCurrentUser();
    } catch (e) {
      try {
        await AuthService.refreshToken();
      } catch (e) {
        await AuthService.logout();
      }
    }
  }
  if (!isAuthorized && !to.path?.startsWith('/auth/')) {
    next({name: 'Login'});
  } else if (isAuthorized &&
    (to.path?.startsWith('/auth/') ||
      (to.meta?.permissions && !isPermission(to.meta.permissions, store.state.user.permissions))
    )) {
    next({name: 'Home'});
  } else {
    next();
  }
});

export default router;
