import {Module, Mutation, VuexModule} from 'vuex-module-decorators';
import IUserInfo from '@/types/IUserInfo';
import ITerm from '@/types/ITerms';

@Module({
  namespaced: true,
  name: 'user',
})
export default class User extends VuexModule {
  permissions: string[] = [];
  companyId: number = 0;
  companyName: string = '';
  firstName: string = '';
  id: number = 0;
  lastName: string = '';
  mail: string = '';
  role: string = '';
  termsToAccept: ITerm[] = [];
  isLoggedAs: boolean = false;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get getPermissions() {
    return this.permissions;
  }

  get getTermsToAccept() {
    return this.termsToAccept;
  }

  get isUser() {
    return !!this.id;
  }

  @Mutation
  setUser(newUser: IUserInfo): void {
    this.permissions = newUser.permissions;
    this.companyId = newUser.companyId;
    this.companyName = newUser.companyName;
    this.firstName = newUser.firstName;
    this.id = newUser.id;
    this.lastName = newUser.lastName;
    this.mail = newUser.mail;
    this.role = newUser.role;
  }

  @Mutation
  setTermsToAccept(terms: ITerm[]) {
    this.termsToAccept = terms;
  }

  @Mutation
  setIsLoggedAs(isLoggedAs: boolean): void {
    this.isLoggedAs = isLoggedAs;
  }
}
