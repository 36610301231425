import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/modules/User';
import layout from '@/store/modules/Layout';
import translations from '@/store/modules/Translations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    layout,
    translations,
  },
});
