import axios from 'axios';
import ILoginForm from '@/types/ILoginForm';
import IRegistrationForm from '@/types/IRegistrationForm';
import IRemindPasswordForm from '@/types/IRemindPasswordForm';
import IResetPasswordForm from '@/types/IPasswordForm';
import IUserForm from '@/types/IUserForm';
import {getLocalStorage} from '@/utils/localStorage';

export default class AuthRepository {
  static async login(credentials: ILoginForm): Promise<any> {
    return axios.post('public/login', credentials);
  }

  static register(credentials: IRegistrationForm): Promise<any> {
    return axios.post('public/register', credentials);
  }

  static remindPassword(credentials: IRemindPasswordForm): Promise<any> {
    return axios.post(`public/remind-password/${credentials.mail}`);
  }

  static resetPassword(credentials: IResetPasswordForm, token: string): Promise<any> {
    return axios.post(`public/reset-password/${token}`, credentials);
  }

  static activateAccount(token: string) {
    return axios.post(`public/activate/${token}`);
  }

  static getRegisterForm(): Promise<any> {
    return axios.get('public/form');
  }

  static getUserInfo(): Promise<any> {
    return axios.get('private/user');
  }

  static logout(): Promise<any> {
    return axios.get('public/logout');
  }

  static getTermsToAccept(languageId: number): Promise<any> {
    return axios.get(`/company/user/term/${languageId}/accept`);
  }

  static async refreshToken(refreshToken: string): Promise<any> {
    axios.defaults.headers.common.Authorization = 'Basic YnJvd3Nlcjo=';
    const data = await axios.post('public/refresh', {refreshToken});
    axios.defaults.headers.common.Authorization = getLocalStorage('token') || 'Basic YnJvd3Nlcjo=';

    return data;
  }

  static updateUser(user: IUserForm): Promise<any> {
    return axios.put('private/user/edit-data', user);
  }

  static updatePassword(password: string) {
    return axios.put('private/user/edit-password', {password});
  }

  static acceptTerms(termIds: number[]) {
    return axios.put('/company/user/term/accept', {acceptedTerms: termIds});
  }

  static getAcceptedTerms(languageId: number): Promise<any> {
    return axios.get(`/company/user/term/${languageId}/1/100`);
  }
}
