import AuthFactory from '@/factories/AuthFactory';
import AuthRepository from '@/repositories/AuthRepository';
import IUserInfo from '@/types/IUserInfo';
import store from '@/store/index';
import IUserForm from '@/types/IUserForm';
import {ILanguage} from '@/types/TranslationTypes';
import ITerm from '@/types/ITerms';

export default class UserService {
  static async getCurrentUser(): Promise<void> {
    const user: IUserInfo = await AuthFactory.toUserInfo(await AuthRepository.getUserInfo());
    const isLoggedAs = user.role === 'ADMIN_AS_USER' && user.permissions.includes('admin.logout_as');
    store.commit('user/setIsLoggedAs', isLoggedAs);
    store.commit('user/setUser', user);
  }

  static setUser(user: IUserInfo) {
    store.commit('user/setUser', user);
  }

  static updateUser(user: IUserForm) {
    return AuthRepository.updateUser(user);
  }

  static async getTermsToAccept(language: ILanguage): Promise<ITerm[]> {
    const data = await AuthRepository.getTermsToAccept(language.id || 0);
    return AuthFactory.toTerms(data);
  }

  static acceptTerms(termsIds: number[]) {
    return AuthRepository.acceptTerms(termsIds);
  }

  static async getAcceptedTerms(languageId: number) {
    const data = await AuthRepository.getAcceptedTerms(languageId);
    return AuthFactory.toTerms(data);
  }
}
